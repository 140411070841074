.monitor div {
    display: flex;
    flex-direction: column;
}

.monitor div p {
    margin-bottom: 8px;
}

.monitor div .type {
    margin-left: 18px;
}

.pop-zhanrs {
    margin-left: 28px;
}

.pop-zhanrs:first-child {
    margin-top: 8px;
}

.select_abc {
    padding: 10px;
    border-radius: 8px;
    width: 40% !important;
}