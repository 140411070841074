.wrapper-err{
    font-family: 'Segoe UI', Tahoma, sans-serif;
    font-size: 75%;
}

.conten-err{
    color: rgb(95, 99, 104);
    font-size: 1em;
    line-height: 1.55;
    margin: 0 auto;
    max-width: 600px;
    padding-top: 100px;
    position: relative;
    width: 100%;
}

.dino{
    margin-bottom: 20px;
    background-image: url(./err-dino.png);
    width: 72px;
    height: 72px;
}

.text-title-err>h1{
    margin: 0 0 15px;
}

.text-err>p:nth-child(1){
    font-size: 14px;
    margin: 0;
}

.text-err>ul{
    margin-top: 0;
}

.text-err>ul>li{
    font-size: 16px;
}

.text-err>ul>li>a{
    text-decoration: none;
}