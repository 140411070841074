.display-none {
    display: none;
}

.modalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 999;
    overflow: auto;
}

.modal {
    position: fixed;
    background: white;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 520px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow: inherit;
    max-height: calc(100vh - 120px);
}

.buttonClose {
    width: 30%;
    margin: 0 auto;
    outline: none;
    border: none;
    padding: 10px 0;
    border-radius: 8px;
    font-size: 18px;
    background-color: #c0c0c0;
    cursor: pointer;
}

.modalTitle{
    font-size: 30px;
    text-align: center;
    margin-bottom: 10px;
}

.modalText{
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}