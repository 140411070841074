.choice-item-book-add {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #c7c7c7;
  max-width: 1200px;
  border-radius: 12px;
  padding: 10px 30px;
  margin: 10px 0;
  cursor: pointer;
}

.choice-item-book-add-img {
  width: 14px;
  transform: rotate(180deg);
}

.choice-item-book-add-text {
  font-size: 18px;
}

.reader-photo {
  display: flex;
  flex-direction: column;
  max-width: 200px;
  width: 200px;
  height: 285px;
  margin: auto;
  margin-top: 20px;
}

.reader-photo-add {
  display: table;
  margin: auto;
  text-align: center;
}

.choice-item-book-add:hover {
  background-color: #063d64;
  border: 1px solid #063d64;
}

.choice-item-book-add:hover .choice-item-book-add-text {
  color: #fff;
}

.choice-item-book-add:hover .choice-item-book-add-img {
  filter: brightness(15);
}

.form-add-book,
.form-add-article {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 10px;
}

.form-add-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  border: 1px solid #8f8f8f;
  border-radius: 15px;
  margin-top: 30px;
  max-width: 1350px;
}

.search-filter-book,
.search-filter-article {
  max-width: 300px;
  margin-right: 30px;
}

.search-filter-book > label > input {
  margin-left: 20px;
}

.search-filter-book:first-child {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.search-filter-book:nth-child(6) {
  grid-area: 3 / 1 / 4 / 2;
}

.search-filter-book:nth-child(7) {
  grid-area: 3 / 2 / 4 / 3;
}

.search-filter-book:not(:first-child),
.search-filter-article:not(:first-child) {
  margin-top: 10px;
}

.search-filter-book > input,
.search-filter-article > input {
  width: 250px;
  padding: 5px;
  outline: none;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #8f8f8f;
  margin-top: 5px;
}

.search-filter-book > select,
.search-filter-article > select {
  padding: 5px;
  margin-top: 10px;
  outline: none;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #8f8f8f;
}

.form-add-img {
  width: 230px;
  height: 290px;
  background-color: #d9dbda;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-add-img-btn {
  display: flex;
  flex-direction: column;
}
