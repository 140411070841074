.monitor div{
    display: flex;
    flex-direction: column;
}
.monitor div p{
    margin-bottom: 8px;
}
.monitor div .type{
    margin-left: 18px;
}
.pop-zhanrs{
    margin-left: 28px;
}
.pop-zhanrs:first-child{
    margin-top: 8px;
}

.df{
    display: flex;
    align-items: center;
}
.search-filter-book__book-p{
    margin: 0 18px;
}