
.form-wrapper{
  width: 100%;
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr;

}

.form-input span{
  display: block;
  font-weight: 700;
  color: #000000;
  margin-bottom: 3px;
}

.form-input input,select{
  padding: .5rem;
  border-radius: 8px;
  border: 1px solid #00000030;
  width: 100%;
  outline-color: #a5c3f5;
}


.form-radio label{
  display: flex;
}

.form-radio input{
  width: initial;
}



@media (min-width: 1200px) {
  .form-wrapper{
    grid-template-columns: 1fr 1fr 1fr;

  }
 
}


.choice-item-book-add {
 display: flex;
 align-items: center;
 justify-content: space-between;
 border: 1px solid #c7c7c7;
 max-width: 1200px;
 border-radius: 12px;
 padding: 10px 30px;
 margin: 10px 0;
 cursor: pointer;
}

.choice-item-book-add-img {
 width: 14px;
 transform: rotate(180deg);
}

.choice-item-book-add-text {
 font-size: 18px;
}

.choice-item-book-add:hover {
 background-color: #063d64;
 border: 1px solid #063d64;
}

.choice-item-book-add:hover .choice-item-book-add-text {
 color: #fff;
}

.choice-item-book-add:hover .choice-item-book-add-img {
 filter: brightness(15);
}

.form-add-book,
.form-add-article {
 display: grid;
 grid-template-columns: repeat(3, 1fr);
 grid-column-gap: 0px;
 grid-row-gap: 10px;
}

.form-add-wrapper {
 display: flex;
 align-items: center;
 justify-content: space-between;
 padding: 30px;
 border: 1px solid #8f8f8f;
 border-radius: 15px;
 margin-top: 30px;
 max-width: 1350px;
}

.search-filter-book,
.search-filter-article {
 max-width: 300px;
 margin-right: 30px;
}

.search-filter-book:first-child {
 display: flex;
 flex-direction: column;
 justify-content: flex-end;
}

.search-filter-book:nth-child(6) {
 grid-area: 3 / 1 / 4 / 2;
}

.search-filter-book:nth-child(7) {
 grid-area: 3 / 2 / 4 / 3;
}

.search-filter-book:not(:first-child),
.search-filter-article:not(:first-child) {
 margin-top: 10px;
}

.search-filter-book > input,
.search-filter-article > input {
 width: 250px;
 padding: 5px;
 outline: none;
 font-size: 14px;
 border-radius: 8px;
 border: 1px solid #8f8f8f;
 margin-top: 5px;
}

.search-filter-book > select,
.search-filter-article > select {
 padding: 5px;
 margin-top: 10px;
 outline: none;
 font-size: 14px;
 border-radius: 8px;
 border: 1px solid #8f8f8f;
 width: 100%;
}

.form-add-img {
 width: 230px;
 height: 290px;
 background-color: #d9dbda;
 display: flex;
 align-items: center;
 justify-content: center;
}

.form-add-img-btn {
 display: flex;
 flex-direction: column;
}

.formEdit > input {
 /* width: 250px; */
 padding: 5px;
 outline: none;
 font-size: 14px;
 border-radius: 8px;
 border: 1px solid #8f8f8f;
 margin-top: 5px;
}
.formEdit > select {
 width: 184px;

 padding: 5px;
 outline: none;
 font-size: 14px;
 border-radius: 8px;
 border: 1px solid #8f8f8f;
 margin-top: 5px;
}
.search-lib-name {
 width: 100%;
 display: inline-block;
 padding: 3px;
 background-color: #d1d1d1;
 border: 1px solid #063d64;
 cursor: pointer;
}

.btn_add_book_copy {
 padding: 8px 60px;
 margin: 0 auto;
 outline: none;
 border: none;
 border-radius: 8px;
 background-color: rgb(235, 141, 0);
 color: rgb(255 255 255);
 cursor: pointer;
 margin-top: 25px;
}
