.monitor {
 display: flex;
}
.monitor div {
 display: flex;
 flex-direction: column;
}
.monitor div p {
 margin-bottom: 8px;
}
.monitor div .type {
 margin-left: 18px;
 background: #f2f5fa;
 padding: 20px;
 border-radius: 20px;
 font-family: cursive;
 color: #21395f;
}
.select_abc {
 width: 20%;
}
.colunm > div > h3 {
 margin-bottom: 15px;
}

.headerRead {
 background: #a5c3f5;
 border-radius: 10px;
 padding: 10px;
 margin-left: 18px;
 text-align: center;
 font-family: cursive;
 color: #21395f;
}
