.header {
  background-color: #fff;
  color: #fff;
}

.header-wrapper {
  margin: 0 50px;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 0;
}

.header-profile-link,
.header-out-link {
  display: flex;
  align-items: center;
  margin: auto;
}

.header-out-link {
  cursor: pointer;
  margin: 0;
}

.header-profile-link > img {
  height: 40px;
  margin-left: 15px;
  padding-right: 15px;
}

.header-out-link > img {
  height: 25px;
  margin: 0 15px;
}

.header-wrapper p {
  color: #000;
}
