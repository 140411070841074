.monitor div {
  display: flex;
  flex-direction: column;
}

.monitor div p {
  margin: 0 !important;
}

.page-win {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  width: 100%;
  height: 30vh;
}

.static-tables {
  display: flex;
  gap: 20px;
}
