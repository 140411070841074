.choice-item-book-add {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #c7c7c7;
    max-width: 1200px;
    border-radius: 12px;
    padding: 10px 30px;
    margin: 10px 0;
    cursor: pointer;
}

.choice-item-book-add-img {
    width: 14px;
    transform: rotate(180deg);
}

.choice-item-book-add-text {
    font-size: 18px;
}

.choice-item-book-add:hover {
    background-color: #063d64;
    border: 1px solid #063d64;
}

.choice-item-book-add:hover .choice-item-book-add-text {
    color: #fff;
}

.choice-item-book-add:hover .choice-item-book-add-img {
    filter: brightness(15);
}


.form-add-book,
.form-add-article {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 10px;
}

.form-add-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    border: 1px solid #8f8f8f;
    border-radius: 15px;
    margin-top: 30px;
    max-width: 1350px;
}

.search-filter-book,
.search-filter-article {
    max-width: 300px;
    margin-right: 30px;
}

.search-filter-book:not(:first-child),
.search-filter-article:not(:first-child) {
    margin-top: 10px;
}

.rubrika {
    display: flex;
    align-items: center;
    position: relative;
}

.input-rubrika {
    width: 250px;
    padding: 5px;
    outline: none;
    font-size: 14px;
    border-radius: 8px;
    border: 1px solid #8f8f8f;
}


.rubrika>input,
.search-filter-article>input {
    width: 250px;
    padding: 5px;
    outline: none;
    font-size: 14px;
    border-radius: 8px;
    border: 1px solid #8f8f8f;
    margin-top: 5px;
}

.rubrika>p {
    margin-bottom: 10px;
}

.search-filter-book-rubrik>select,
.search-filter-article>select {
    padding: 5px;
    outline: none;
    font-size: 14px;
    border-radius: 8px;
    border: 1px solid #8f8f8f;
}

.search-filter-book-rubrik {
    display: flex;
    align-items: center;
}

.search-filter-book>div>p {
    font-weight: 700;
    font-size: 28px;
    cursor: pointer;
    margin-left: 10px;
}

.form-add-img {
    width: 230px;
    height: 290px;
    background-color: #d9dbda;
    display: flex;
    align-items: center;
    justify-content: center;
}


.form-add-img-btn {
    display: flex;
    flex-direction: column;
}


/* Модалка жанра */
.modal-content1 {
    width: 300px;
}

.modal-title-icon,
.alert-title-icon {
    display: flex;
    justify-content: space-between;
}

.alert-title-icon>h1 {
    margin-bottom: 20px;
}

.modal-close-icon {
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.modal-content1>form>input {
    margin: 20px 0;
    width: 250px;
    padding: 5px;
    outline: none;
    font-size: 14px;
    border-radius: 8px;
    border: 1px solid #8f8f8f;
}