.modal1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
}

.modal-main1 {
  position: fixed;
  background: white;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 800px; */
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: inherit;
  max-height: calc(100vh - 120px);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.modal-main button {
  margin: 0 auto;
  padding: 10px;
  margin-bottom: 20px;
}

.btns {
  display: flex;
}

.alert-spisan {
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.alert-spisan h1 {
  margin-bottom: 20px;
}

.alert-spisan div {
  margin-bottom: 10px;
}
.alert-spisan > input {
  width: 100%;
  padding: 4px;
  border: none;
  border-bottom: 1px solid black;
  font-size: 16px;
  outline: none;
  margin: 18px 0;
}

.select_alert_inv_number{
  width: 100%;
  padding: 4px;
  outline: none;
  margin-bottom: 15px;
  font-size: 14px;
  border-radius: 8px;
  border: 3px solid #09233c2e;
}