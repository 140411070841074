.table{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.row{
    display: flex;
    align-items: center;
    border: 1px solid #c2c2c2;
    /* height: 60px; */
}
.row-item6{
    width: calc(100%/5);
    align-items: center;
    display: flex;
    justify-content: end;
    height: 100%;
    border-left: 1px solid #c2c2c2;
    border-right: 1px solid #c2c2c2;
}
.row-item6>.sub-row-item6{
    height: 100%;
    align-items: center;
    display: flex;
    border-bottom: 1px solid #c2c2c2;
}
.row-subitem__item>p, .border>p{
    border-bottom: 1px solid #c2c2c2;
}
.row-subitem__item:not(:last-child),
.row-subitem__item>div>p:not(:last-child){
    border-right: 1px solid #c2c2c2;
}
.row-item6 p{
    text-align: center;
    width: 100%;
}
.row-subitem{
    width: 100%;
    display: flex;
    align-items: center;
}
.row-subitem>div{
    width: 100%;
}
.row-subitem__item{
    width: 100%;
    text-align: center;
}
.row-subitem__item p{
    width: 100%;
}
.row-subitem__item div{
    display: flex;
    justify-content: space-around;
}

.flex{
    flex-direction: column;
}
.flex__sub{
    justify-content: space-around;
}
.row-sub__item{
    width: 100%;
    display: flex;
    align-items: center;
}
.row-subitem__data{
    display: flex;
    width: calc(100%/4);
    justify-content: space-around;
    align-items: center;
}
.w{
    width: 100%;
}
.border-r>p{
    border-right: 1px solid #c2c2c2;
}
.border-t{
    border-top: 1px solid #c2c2c2;
}
.count-app{
    height: 150px;
}