.book-card {
  margin-top: 20px;
  width: 480px;
  height: 264.565px;
  background-color: #fff;
  border: 1px solid black;
  /* display: none; */
}
.book-card-wrapper {
  padding: 18px;
  display: flex;
  flex-direction: column;
  height: 100%;
  /* justify-content: space-between; */
}
.book-card-top {
  display: flex;
  justify-content: space-between;
}
.book-card p {
  font-size: 12px;
  font-weight: 500;
}

.book-redline {
  text-indent: 30px;
}
