.menu {
 width: 287px;
 position: absolute;

 height: 100vh;
 background: linear-gradient(180deg, rgba(227, 236, 252, 1) 76%, rgba(255, 255, 255, 1) 100%);

 z-index: 100;
}

.notMenu {
 transform: translateX(-220px);
 transition: 0.5s ease-out;
}

.menu-up-content {
 /* padding: 35px 30px; */
}

.menu-icon-up {
 display: flex;
 align-items: center;
 justify-content: space-between;
}

.menu-icon-up > img {
 height: 15px;
 cursor: pointer;
}

.menu-logo {
 margin-top: 35px;
 display: flex;
 align-items: center;
 text-decoration: none;
 justify-content: center;
 margin-bottom: 20px;
}

.menu-logo > img {
 width: 180px;
 margin-right: 20px;
}

.menu-logo > h1 {
 color: #063d64;
}

.item {
 display: flex;
 align-items: center;
 padding: 15px 0 15px 15px;
 cursor: pointer;
 margin: 0 10px;
 text-decoration: none;
}

.item:hover {
 background-color: #063d64;
 border-radius: 12px;
 box-shadow: 3px 4px 16px #063d64;
 -webkit-box-shadow: 3px 4px 16px #063d64;
 -moz-box-shadow: 3px 4px 16px #063d64;
}

.item:hover .item-img {
 filter: brightness(3);
}

.item:hover .item-text {
 color: #fff;
}

.item > img {
 width: 25px;
 margin-right: 20px;
}

.item-text {
 font-size: 14px;
 font-weight: 500;
 color: #898989;
}

.administr {
 /* padding: 0 30px; */
}

.administr .adm-main {
 font-weight: 700;
 font-size: 18px;
 cursor: pointer;
 font-weight: 700;
 font-size: 18px;
 cursor: pointer;
 position: relative;
 width: 200px;
 margin: 3px auto;
 padding: 4px;
 background: #a5c3f5;
 border-radius: 10px;
 border: 1px solid rgb(33 57 95 / 0%);
 box-shadow: 0 1px 1px rgb(50 50 50 / 10%);
 cursor: pointer;
 outline: none;
 font-weight: bold;
 color: #21395f;
}

.administr .adm-pod {
 /* padding-left: 10px; */
 margin-left: 30px;
}

.administr .adm-pod a {
 text-decoration: none;
 font-size: 14px;
}

.administr .adm-pod a:hover {
 color: blue;
}

.administr .adm-pod .link {
 margin-top: 5px;
 font-weight: 700;
 font-size: 14px;
 position: relative;
 width: 200px;
 margin: 5px auto;
 padding: 2px;
 background: #a5c3f540;
 border-radius: 10px;
 border: 1px solid rgb(33 57 95 / 12%);
 box-shadow: 0 1px 1px rgb(50 50 50 / 10%);
 cursor: pointer;
 outline: none;
 /* font-weight: bold; */
 color: #21395f;
 padding-left: 8px;
}
.link {
 display: block;
}
.unviz {
 display: none;
}

.active {
 color: blue !important;
 font-weight: bold;
 border: 2px solid !important;
}

.menu-items a {
 color: black;
}

.notification::after {
 content: '!';
 position: absolute;
 right: 0;
 top: 0;
 padding: 5px 7px;
 background-color: red;
 border-radius: 10px;
 color: white;
}

.manytext {
 padding-right: 10px !important;
}
