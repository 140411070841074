.cellMain,
.cellButton,
.cellHover {
 border-bottom: 1px solid !important;
 padding: 5px !important;
 position: relative;
 background: #a5c3f540 !important;
 border: 1px solid #8caadd !important;
}

.cell {
 font-size: 16px !important;
 font-weight: bold !important;
 border: 1px solid #8caadd !important;
 background: #a5c3f540 !important;
 color: #21395f !important;
}

.cellMain {
 font-size: 16px !important;
 font-weight: bold !important;
 border: 1px solid #8caadd !important;
 background: #a5c3f5 !important;
 color: #21395f !important;
}

.tableContainer {
 overflow: initial !important;
}

.button,
.buttonDel {
 background: #a5c3f5;
 border-radius: 10px;
 border: 1px solid rgb(33 57 95 / 0%);
 cursor: pointer;
 outline: none;
 color: #21395f;
 padding: 5px;
 width: 100%;
 margin-bottom: 5px;
 display: block;
}

.buttonDel {
 background: #ff6347bf;
 color: #6e0c0c;
}

.button:hover,
.buttonDel:hover {
 opacity: 0.8;
}

.cellHover {
 cursor: pointer;
 padding-bottom: 20px !important;
}

.cellHover::before {
 content: 'Посмотреть';
 box-sizing: border-box;
 position: absolute;
 bottom: 0;
 font-size: 11px;
 left: 0;
 background-color: #cddbf1;
 padding: 2px;
 width: 100%;
}

.cellHover:hover::before {
 content: '';
 background-color: initial;
}

.cellHover:hover::after {
 content: attr(data-title);
 position: absolute;
 right: 30%;
 top: 30%;
 z-index: 1;
 background: rgba(167, 165, 187, 0.9);
 font-size: 12px;
 padding: 5px 10px;
 border: 1px solid #333;
}

.libs {
 background: #a5c3f5 !important;
}

.libs1 {
 background: #86a7df !important;
 cursor: pointer;
 border-radius: 10px;
 padding: 5px;
}

.libs2 {
 background: #86a7df !important;
 margin-bottom: 2px;
 padding: 5px;
 cursor: pointer;
 border-radius: 10px;
}

.inventory_numbers_bookAdd {
 background: #afc8f5 !important;
 border-radius: 10px;
 margin-bottom: 2px;
 height: 22px;
}

.notAccepted {
 background: #ddb4ac !important;
}
