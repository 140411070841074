* {
 margin: 0;
 padding: 0;
}

.page-out-wrapper {
 max-width: 1600px;
 margin: 0 auto;
}

.title-total {
 margin: 70px 0 40px 0;
 display: flex;
 align-items: center;
 justify-content: space-between;
}

.searchReader {
 flex-wrap: nowrap !important;
 max-height: calc(50vh - 100px);
 overflow-y: scroll;
}

.total-content {
 display: flex;
 align-items: center;
}

.total-content > div {
 display: flex;
 align-items: center;
}

.total-content > div:not(:last-child) {
 margin-right: 50px;
}

.total-content > div > span {
 padding: 10px 15px;
 border-radius: 8px 0 0 8px;
 outline-offset: -5px;
 outline-width: 5px;
 outline-style: solid;
 outline-color: #063d64;
 font-weight: 700;
}

.total-content > div > p {
 padding: 10px 15px;
 border-radius: 0 8px 8px 0;
 color: #fff;
 background-color: #063d64;
 font-weight: 700;
}

.smart-search {
 padding: 0 0 15px 0;
}

.smart-search > input {
 outline: none;
 border-radius: 8px;
 border: 1px solid black;
 padding: 5px;
 width: 450px;
}

.choice-type {
 display: flex;
 border-bottom: 1px solid #c5c7c6;
 margin: 40px 0;
}

.choice-type > h3 {
 display: flex;
 align-items: center;
 text-align: center;
 padding: 0 55px 3px 55px;
 cursor: pointer;
}

.choice-type > h3:hover {
 padding-bottom: 0px;
 border-bottom: 1px solid #063d64;
 border-inline-start-width: thin;
}

.book-items-header,
.reader-items-header {
 display: flex;
 align-items: center;
 justify-content: space-around;
 margin: 0 auto;
 width: 100%;
 border-bottom: 2px solid rgb(53 80 99);
}

.book-items-header > div {
 display: flex;
 align-items: center;
 width: calc(100% / 8);
 margin: 10px 0;
 justify-content: center;
}

.reader-items-header > div {
 display: flex;
 align-items: flex-end;
 width: calc(100% / 8);
 margin: 10px 0;
 justify-content: center;
}

.book-items-header img,
.reader-items-header img {
 height: 14px;
 margin-left: 20px;
 cursor: pointer;
}

.book-items-data,
.reader-items-data {
 display: flex;
 align-items: center;
 justify-content: space-around;
 padding: 5px 0;
 color: black;
 transition: background 0.2s ease-in-out;
}
.book-items-content a {
 text-decoration: none;
}
.book-items-data:hover {
 background: #0033ff23;
}

.gray {
 background-color: aliceblue;
}

.book-items-data > div {
 width: 200px;
 display: flex;
 align-items: center;
 justify-content: space-between;
 margin: 0 10px;
 text-align: center;
}

.reader-items-data > div {
 width: calc(100% / 7);
 display: flex;
 align-items: center;
 justify-content: center;
 margin: 0 10px;
 text-align: center;
}

.book-items-data > div > p,
.reader-items-data > div > p {
 text-align: center;
}

.book-items-data > a {
 text-align: center;
 text-decoration: none;
 color: #007bff;
 width: 200px;
}

.book-items-content {
 margin-bottom: 25px;
}

.search_output_null {
 text-align: center;
 margin-top: 50px;
}

.search_output_null > h1 {
 transform: rotate(90deg);
 font-size: 60px;
}

/* Главная страница  */
.page-main-header {
 margin-top: 30px;
 display: flex;
 align-items: center;
 justify-content: space-between;
}

.page-main-item {
 width: 210px;
 display: flex;
 flex-direction: column;
 align-items: center;
 border-radius: 8px;
}

.page-main-item:hover {
 box-shadow: 11px 5px 18px #3d3d3d;
}

.page-main-item > div {
 display: flex;
 align-items: center;
 width: 100%;
 height: 50px;
 justify-content: center;
 border-radius: 8px;
 padding: 0 15px;
 font-weight: 700;
 text-align: center;
}

.page-main-item > div:nth-child(1) {
 background-color: #a5c3f5;
 color: #233c65;
}

.page-main-item > div:nth-child(3) {
 background-color: #21395f;
 color: #f7ffff;
}

.page-main-item > img {
 height: 110px;
 margin: 25px 0;
}

/* temp */
.panel {
 padding: 100px 0 30px 0;
 width: 100%;
}

.graf-last {
 margin-bottom: 100px;
}

.page-main-graf {
 display: flex;
 flex-direction: column;
}

/* Modal Reg Reader */
.form-add-book-regReader {
 display: grid;
 grid-template-columns: repeat(2, 1fr);
 grid-column-gap: 0px;
 grid-row-gap: 10px;
}

.product-modal-inner {
 display: flex;
 align-items: flex-start;
}

.search-filter-book-radio {
 display: flex;
 flex-direction: column;
 justify-content: space-evenly;
}

.reader-items-data > div:last-child {
 display: flex;
 flex-direction: column;
}

.reader-items-data > div:last-child button {
 margin: 4px 0;
}

.type-catalog-books {
 margin-top: 30px;
}

.type-catalog-books > p {
 margin-bottom: 10px;
}

@media (max-width: 1919px) {
 .book-items-data > div,
 .book-items-header > div,
 .book-items-data > a {
  width: 100px;
 }

 .book-items-header img,
 .reader-items-header img {
  margin-left: 10px;
 }

 .page-main-item {
  width: 180px;
 }

 .panel,
 .graf,
 .graf-last {
  width: 100%;
 }
}

@media (max-width: 1290px) {
 .book-items-data > div,
 .book-items-header > div,
 .book-items-data > a {
  width: 75px;
 }

 .book-items-header img,
 .reader-items-header img {
  margin-left: 5px;
 }

 .page-main-item {
  width: 150px;
 }

 .page-main-item > div {
  height: 35px;
 }

 .page-main-item > div > p {
  font-size: 12px;
 }

 .page-main-item > img {
  height: 80px;
 }

 .panel,
 .graf,
 .graf-last {
  width: 100%;
 }
}

.modal-content-staff-fullinfo {
 display: flex;
 align-items: center;
 padding: 5px 0;
}

.modal-content-staff-fullinfo > p:nth-child(1) {
 font-weight: 700;
 border-bottom: 1px solid black;
}

.modal-content-staff-fullinfo > p:nth-child(2) {
 margin-left: 15px;
}

.recrut_icon_edit {
 width: 30px;
 cursor: pointer;
}

.input-edit-staff {
 margin-left: 10px;
 padding: 5px;
 font-size: 16px;
 width: 183px;
 padding: 10px;
 outline: none;
 font-size: 14px;
 border-radius: 8px;
 border: 1px solid #8f8f8f;
}

.filter-serach {
 position: relative;
}

.searchAvtor-wrapper {
 display: flex;
 flex-direction: column;
 flex-wrap: wrap;
 position: absolute;
 top: 1.5em;
 left: 0;
 z-index: 100;
}

.searchAvtor {
 padding: 3px 10px;
 background-color: rgb(140, 252, 140);
 border: 1px solid green;
 font-weight: 700;
 cursor: pointer;
}

.searchAvtor:hover {
 background-color: rgb(208, 252, 208);
}

.input-delete-staff {
 padding: 10px;
 margin: 10px 0;
 width: 100%;
 font-size: 18px;
 border: 1px solid black;
 outline: none;
 border-radius: 8px;
}

.df {
 display: flex;
 flex-direction: row !important;
}
.wrapper-filter {
 width: 100%;
 display: flex;
 justify-content: flex-end;
 margin-top: 40px;
 margin-bottom: 25px;
}

.filter-readers {
 display: flex;
 max-width: 295px;
 height: 40px;
 width: 90%;
 margin-right: 30px;
 justify-content: flex-start;
}

.filter-readers label {
 width: 110px;
 display: flex;
 align-items: center;
 justify-content: flex-end;
 font-weight: bold;
 margin-right: 10px;
}

.filter-readers input {
 width: 100%;
 border-radius: 5px;
 border: 1px solid black;
 padding: 2px 5px;
 cursor: pointer;
}

.filter-button {
 width: 150px;
 height: 35px;
 font-size: 17px;
 font-weight: 600;
 color: white;
 border-radius: 5px;
 border: none;
 margin-right: 50px;
 cursor: pointer;
 transition: background 0.3s ease;
 margin-bottom: 10px;
}

.filter-button-green:hover {
 background-color: #12522b;
}

.filter-button-red:hover {
 background: #a5412e;
}

.filter-button-green {
 background: #23a255;
}

.filter-button-red {
 background: #fd6445;
 width: 170px;
}

.page-main-title {
 margin-top: 70px;
 text-align: center;
 font-size: 25px;
}

.wrapper-reader-button {
 margin-bottom: 10px;
}

.user-info-image {
 width: 350px;
 height: 350px;
 margin: 20px;
}

.sideBar {
 background: #dee9f0;
 margin: 10px;
 width: 35%;
 height: 720px;
 border-radius: 10px;
}

.leftSide {
 background: #f2f5f8;
 margin: 10px;
 width: 65%;
}

.userName {
 text-align: center;
 background: #dee9f0;
 margin: auto;
 width: 45%;
 border-radius: 10px;
 border: 1px solid #8aa8dd;
}

.dataField {
 background: #a5c3f5;
 width: 90%;
 margin: 6px auto;
 border-radius: 10px;
 padding: 4px;
 border: 1px solid #8aa8dd;
}

.sideBarInner {
 background: #f2f5fa;
 width: 90%;
 height: 700px;
 margin: auto;
 margin-top: 10px;
 border-radius: 10px;
}

.sideBarInnerHeader {
 background: #a5c3f5;
 width: 100%;
 margin: 6px auto;
 height: 50px;
 border-radius: 7px;
 padding: 4px;
 text-align: center;
 border: 1px solid #8aa8dd;
 font-weight: bold;
}

.sideBarInnerBody {
 background: #a5c3f5;
 width: 100%;
 margin: 6px auto;
 height: 40.4px;
 border-radius: 7px;
 padding: 4px;
 text-align: center;
 border: 1px solid #8aa8dd;
}

.leftSideBottom {
 background: #dee9f0;
 width: 100%;
 margin: 6px auto;
 height: 250px;
 border-radius: 7px;
 padding: 4px;
 text-align: center;
 border: 1px solid #8aa8dd;
}

.dataFieldBottom {
 background: #a5c3f5;
 width: 90%;
 margin: 6px auto;
 border-radius: 10px;
 padding: 4px;
 border: 1px solid #8aa8dd;
 cursor: pointer;
}

.MainPageTemp {
 display: flex;
 margin: 0 auto;
 max-width: 1080px;
}

.MainPageTemp2 {
 background: '#f2f5fa';
 display: 'flex';
}

.areaChartsColumn {
 margin: auto;

 display: table;
 text-align: center;
}

.areaChartsWrapper {
 margin: 0 auto;
 margin-top: 60px;
}
