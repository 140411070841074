.block-chart{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.chart {
    font-family: sans-serif;
    text-align: center;
    width: 300px;
  }
  
  .chart>div{
    width: 300px;
    height: 300px;
  }