.electron-res-opis li {
    margin-left: 24px;
    margin-top: 8px;
}

.electron-res-opis h3 {
    margin-top: 28px;
    margin-bottom: 12px;
}

.electron-res-opis p {
    margin: 8px 0;
}

.block-chart {
    display: flex;
    gap: 150px;
}

.chart-container {
    gap: 150px;
    display: flex;
    flex-direction: column;
}

.title-chart {
    margin-bottom: 10px;
}