* {
 margin: 0;
 padding: 0;
}

.page-out-wrapper {
 max-width: 1600px;
 margin: 0 auto;
}

.title-total {
 margin: 70px 0 40px 0;
 display: flex;
 align-items: center;
 justify-content: space-between;
}

.total-content {
 display: flex;
 align-items: center;
}

.total-content > div {
 display: flex;
 align-items: center;
}

.total-content > div:not(:last-child) {
 margin-right: 50px;
}

.total-content > div > span {
 padding: 10px 15px;
 border-radius: 8px 0 0 8px;
 outline-offset: -5px;
 outline-width: 5px;
 outline-style: solid;
 outline-color: #063d64;
 font-weight: 700;
}

.total-content > div > p {
 padding: 10px 15px;
 border-radius: 0 8px 8px 0;
 color: #fff;
 background-color: #063d64;
 font-weight: 700;
}

.smart-search {
 padding: 0 0 15px 0;
}

.smart-search > input {
 outline: none;
 border-radius: 8px;
 border: 5px solid #c1d4f5;
 padding: 5px;
 width: 450px;
}

.choice-type {
 display: flex;
 border-bottom: 1px solid #c5c7c6;
 margin: 40px 0;
}

.choice-type > h3 {
 padding: 0 55px 3px 55px;
 cursor: pointer;
 background: #a5c3f5;
 border-radius: 10px;
 margin: 10px;
 color: #21395f;
}

.choice-type > h3:hover {
 border-bottom: 3px solid #063d64;
 border-inline-start-width: thin;
}

.book-items-header,
.reader-items-header {
 display: flex;
 align-items: center;
 justify-content: space-around;
 margin: 0 auto;
 width: 100%;
 border-bottom: 2px solid rgb(53 80 99);
}

.book-items-header > div {
 display: flex;
 align-items: center;
 width: calc(100% / 8);
 margin: 10px 0;
 justify-content: center;
}

.reader-items-header > div {
 display: flex;
 align-items: flex-end;
 width: calc(100% / 8);
 margin: 10px 0;
 justify-content: center;
}

.book-items-header img,
.reader-items-header img {
 height: 14px;
 margin-left: 20px;
 cursor: pointer;
}

.book-items-data,
.reader-items-data {
 display: flex;
 align-items: center;
 justify-content: space-around;
 padding: 5px 0;
 margin: 3px;
}

.gray {
 background-color: #dee8f9;
 border-radius: 10px;
}

.red {
 background-color: #e9a5a3;
 border-radius: 10px;
}

.book-items-data > div {
 width: 200px;
 display: flex;
 align-items: center;
 justify-content: center;
 margin: 0 10px;
 text-align: center;
}

.reader-items-data > div {
 width: calc(100% / 7);
 display: flex;
 align-items: center;
 justify-content: center;
 margin: 0 10px;
 text-align: center;
}

.book-items-data > div > p,
.reader-items-data > div > p {
 text-align: center;
 overflow: hidden;
}

.book-items-data > a {
 text-align: center;
 text-decoration: none;
 color: #007bff;
 width: 200px;
}

.book-items-content {
 margin-bottom: 25px;
}

.search_output_null {
 text-align: center;
 margin-top: 50px;
}

.search_output_null > h1 {
 transform: rotate(90deg);
 font-size: 60px;
}

/* Главная страница  */
.page-main-header {
 margin-top: 30px;
 display: flex;
 align-items: center;
 justify-content: space-between;
}

.page-main-item {
 width: 210px;
 display: flex;
 flex-direction: column;
 align-items: center;
 border-radius: 8px;
}

.page-main-item:hover {
 box-shadow: 0px 11px 18px 8px #3d3d3d;
}

.page-main-item > div {
 display: flex;
 align-items: center;
 width: 100%;
 height: 50px;
 justify-content: center;
 border-radius: 8px;
 padding: 0 15px;
 font-weight: 700;
 text-align: center;
}

.page-main-item > div:nth-child(1) {
 background-color: #a5c3f5;
 color: #233c65;
}

.page-main-item > div:nth-child(3) {
 background-color: #21395f;
 color: #f7ffff;
}

.page-main-item > img {
 height: 110px;
 margin: 25px 0;
}

/* temp */
.panel {
 padding: 100px 0 30px 0;
 width: 100%;
}

.graf-last {
 margin-bottom: 100px;
}

.page-main-graf {
 display: flex;
 flex-direction: column;
}

/* Modal Reg Reader */
.form-add-book-regReader {
 display: grid;
 grid-template-columns: repeat(2, 1fr);
 grid-column-gap: 0px;
 grid-row-gap: 10px;
}

.product-modal-inner {
 display: flex;
 align-items: flex-start;
}

.search-filter-book-radio {
 display: flex;
 flex-direction: column;
 justify-content: space-evenly;
}

@media (max-width: 1919px) {
 /* .book-items-data>div, */
 .book-items-header > div,
 .book-items-data > a {
  width: 100px;
 }

 .book-items-header img,
 .reader-items-header img {
  margin-left: 10px;
 }

 .page-main-item {
  width: 180px;
  margin: 2px;
 }

 .panel,
 .graf,
 .graf-last {
  width: 100%;
 }
}

@media (max-width: 1290px) {
 .book-items-data > div,
 .book-items-header > div,
 .book-items-data > a {
  width: 75px;
 }

 .book-items-header img,
 .reader-items-header img {
  margin-left: 5px;
 }

 .page-main-item {
  width: 150px;
 }

 .page-main-item > div {
  height: 35px;
 }

 .page-main-item > div > p {
  font-size: 12px;
 }

 .page-main-item > img {
  height: 80px;
 }

 .panel,
 .graf,
 .graf-last {
  width: 100%;
 }
}
