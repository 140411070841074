.base-books-wrapper {
  margin: 50px 60px;
}

.base-books-title {
  width: 100%;
}

.title-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-btn > div:nth-child(1) > p {
  font-size: 18px;
  color: darkblue;
}

.book-irem-reit {
  display: flex;
}

.reit {
  margin-right: 45px;
}

.reit img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.book-info {
  display: flex;
  align-items: flex-start;
  margin-top: 30px;
}

.book-info-left {
  display: flex;
  flex-direction: column;
  /* max-width: 180px; */
}

.book-info-image {
  width: 350px;
  height: auto;
  background-color: tomato;
  margin-bottom: 15px;
}

.book-info-image-news {
  width: 350px;
  height: auto;
  background-color: tomato;
  margin-bottom: 15px;
  box-shadow: 0 5px 90px #888;
}

.book-info-left-del {
  margin-top: 10px;
  background-color: tomato;
  border: none;
  outline: none;
  color: white;
  padding: 10px 0;
  font-size: 18px;
  border-radius: 10px;
  cursor: pointer;
}

.book-info-left-del:hover {
  background-color: rgb(253, 72, 41);
}

.book-info-left button:nth-child(3) {
  margin-top: 10px;
  background-color: #23a359;
  border: none;
  outline: none;
  color: white;
  padding: 10px 0;
  font-size: 18px;
  border-radius: 10px;
  cursor: pointer;
}

.book-info-left button:nth-child(3):hover {
  background-color: #2dbe6a;
}

.book-info-left button:nth-child(4):hover {
  background-color: #284cc4;
}

.book-info-right-book {
  display: flex;
  align-items: flex-start;
  /* justify-content: space-between; */
  /* height: 325px; */
  margin-left: 40px;
  font-weight: 500;
  width: 100%;
}

.book-info-right1:nth-child(2) {
  margin-left: 50px;
}

.book-info-rigth-item {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.book-info-rigth-item > p {
  font-weight: 550;
}

.book-info-rigth-item span {
  font-weight: 300;
  margin-left: 10px;
}

.tags-items {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* justify-content: center; */
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #b8b8b8;
}

.tags-items p {
  background-color: #959595;
  color: #fff;
  padding: 4px;
  margin: 5px;
  cursor: pointer;
  font-size: 14px;
}

.status-book {
  margin-bottom: 15px;
  margin-top: 15px;
}

.status-book > p {
  display: inline;
  padding: 3px;
  border-radius: 8px;
  color: rgb(0, 0, 0);
  font-size: 12px;
}

.hand > p,
.free > p,
.order > p {
  background-color: #c0c0c0;
}

.form-add-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  border: 1px solid #8f8f8f;
  border-radius: 15px;
  margin-top: 30px;
  max-width: 1350px;
}

.search-filter-book-item {
  margin-top: 20px;
  max-width: 300px;
  margin-bottom: 20px;
}

.search-filter-book-item:nth-child(2) {
  position: relative;
}

.search-filter-book-item:first-child {
  max-width: 300px;
  margin-right: 30px;
}

.search-filter-book-item:first-child > input {
  width: 50px;
}

.search-filter-book-item:nth-child(2) > input {
  width: 250px;
}

.search-filter-book-item > input {
  padding: 5px;
  outline: none;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #8f8f8f;
  margin-top: 10px;
}

.search-filter-book-item > select {
  padding: 5px;
  margin-top: 10px;
  outline: none;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #8f8f8f;
}

/* Поиск читателя */

.searchReader {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 100;
}

.searchReader > div > p {
  font-size: 10px;
  font-size: 12px;
  background: aliceblue;
  padding: 2px 0 2px 10px;
  border-bottom: 1px solid blue;
}

.searchReader > div > p:hover {
  background: rgb(175, 218, 255);
  cursor: pointer;
}

/* на время */

.book-info-main {
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.r,
.l {
  width: 50%;
}

.more {
  margin-top: 20px;
  cursor: pointer;
  font-size: 14px;
  color: darkblue;
  font-weight: 600;
}

.more > p {
  margin-left: 100px;
}

.showBookCard {
  margin-top: 30px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
}

.loader {
  --background: linear-gradient(135deg, #23c4f8, #275efe);
  --shadow: rgba(39, 94, 254, 0.28);
  --text: #6c7486;
  --page: rgba(255, 255, 255, 0.36);
  --page-fold: rgba(255, 255, 255, 0.52);
  --duration: 3s;
  width: 200px;
  height: 140px;
  position: relative;
}

.loader:before,
.loader:after {
  --r: -6deg;
  content: '';
  position: absolute;
  bottom: 8px;
  width: 120px;
  top: 80%;
  box-shadow: 0 16px 12px var(--shadow);
  transform: rotate(var(--r));
}

.loader:before {
  left: 4px;
}

.loader:after {
  --r: 6deg;
  right: 4px;
}

.loader div {
  width: 100%;
  height: 100%;
  border-radius: 13px;
  position: relative;
  z-index: 1;
  perspective: 600px;
  box-shadow: 0 4px 6px var(--shadow);
  background-image: var(--background);
}

.loader div ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.loader div ul li {
  --r: 180deg;
  --o: 0;
  --c: var(--page);
  position: absolute;
  top: 10px;
  left: 10px;
  transform-origin: 100% 50%;
  color: var(--c);
  opacity: var(--o);
  transform: rotateY(var(--r));
  -webkit-animation: var(--duration) ease infinite;
  animation: var(--duration) ease infinite;
}

.loader div ul li:nth-child(2) {
  --c: var(--page-fold);
  -webkit-animation-name: page-2;
  animation-name: page-2;
}

.loader div ul li:nth-child(3) {
  --c: var(--page-fold);
  -webkit-animation-name: page-3;
  animation-name: page-3;
}

.loader div ul li:nth-child(4) {
  --c: var(--page-fold);
  -webkit-animation-name: page-4;
  animation-name: page-4;
}

.loader div ul li:nth-child(5) {
  --c: var(--page-fold);
  -webkit-animation-name: page-5;
  animation-name: page-5;
}

.loader div ul li svg {
  width: 90px;
  height: 120px;
  display: block;
}

.loader div ul li:first-child {
  --r: 0deg;
  --o: 1;
}

.loader div ul li:last-child {
  --o: 1;
}

.loader span {
  display: block;
  left: 0;
  right: 0;
  top: 100%;
  margin-top: 20px;
  text-align: center;
  color: var(--text);
}

@-webkit-keyframes page-2 {
  0% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  35%,
  100% {
    opacity: 0;
  }
  50%,
  100% {
    transform: rotateY(0deg);
  }
}

@keyframes page-2 {
  0% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  35%,
  100% {
    opacity: 0;
  }
  50%,
  100% {
    transform: rotateY(0deg);
  }
}

@-webkit-keyframes page-3 {
  15% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  35% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: 0;
  }
  65%,
  100% {
    transform: rotateY(0deg);
  }
}

@keyframes page-3 {
  15% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  35% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: 0;
  }
  65%,
  100% {
    transform: rotateY(0deg);
  }
}

@-webkit-keyframes page-4 {
  30% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  65%,
  100% {
    opacity: 0;
  }
  80%,
  100% {
    transform: rotateY(0deg);
  }
}

@keyframes page-4 {
  30% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  65%,
  100% {
    opacity: 0;
  }
  80%,
  100% {
    transform: rotateY(0deg);
  }
}

@-webkit-keyframes page-5 {
  45% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  80%,
  100% {
    opacity: 0;
  }
  95%,
  100% {
    transform: rotateY(0deg);
  }
}

@keyframes page-5 {
  45% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  80%,
  100% {
    opacity: 0;
  }
  95%,
  100% {
    transform: rotateY(0deg);
  }
}

.loaderBook {
  position: absolute;
  left: 55%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.inv_number_title {
  margin: 0 auto;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.inv_number_title div {
  cursor: pointer;
}

.modal_prompt {
  -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  position: absolute;
  background: white;
  z-index: 0;
  right: 20px;
  width: 200px;
  display: none;
  border-radius: 5px;
  top: -114px;
  transition: display 0.7s ease-in-out;
  font-size: 12px;
  padding: 5px 10px;
}

.inv_number_wrapper {
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  margin-bottom: 21px;
}

.inv_number_wrapper div {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.inv_number_wrapper span {
  cursor: pointer;
}

.item_inv_number {
  padding: 0 5px;
  font-weight: bold;
}

.item_inv_number:hover {
  color: rgba(0, 86, 113, 0.77) !important;
}

.wrap_input_inv_number {
  position: relative;
  width: 100%;
}

.input_inv_number_marker {
  position: absolute;
  right: 140px;
  bottom: 20px;
}

.wrap_input_inv_number > input {
  width: 70%;
  padding: 4px;
  border: none;
  border-bottom: 1px solid black;
  font-size: 16px;
  outline: none;
  margin: 18px 0;
}

.bar_code_bookitem {
  width: 200px;
  height: 116px;
  overflow: hidden;
}

.block_inv_number_shtrih {
  position: absolute;
  left: -1000px;
  width: 100%;
}

.book_formular_btn_close {
  outline: none;
  border: none;
  padding: 10px 0px;
  border-radius: 8px;
  font-size: 16px;
  background-color: #c0c0c0;
  cursor: pointer;
  margin: 0 5px;
}
