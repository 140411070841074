.title-total h1{
    text-transform: uppercase;
}


/* .App {
  font-family: sans-serif;
  text-align: center;
} */

.ShadowWrapper {
    border-top: 1px solid #737374;
    border-left: 1px solid #464648;
    border-right: 1px solid #464648;
    border-bottom: 2px solid #464648;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 0 1px #1a1a1a, 0 8px 20px 6px #888;
  }
  
  .DivWrapperTitle {
    background-color: #2a2b2d;
    height: 36px;
  }
  
  .DivWrapperMonitor {
    display: flex;
    justify-content: space-between;
    background-color: #1e1f21;
    color: #dcdddd;
    padding: 16px;
  }
  
  .TitleWrapper {
    font-weight: bold;
    margin-right: 8px;
  }
  
  .TextWrapper {
    font-weight: bold;
    margin-right: 8px;
    font-size: 32px;
  }
  
  .ButtonsWrapper {
    display: flex;
    align-items: center;
  }
  
  .ButtonWrapper {
    border: unset;
    background-color: #565759;
    height: 20px;
    margin-right: 2px;
    border-radius: 4px;
    color: #e6e6e6;
    outline: unset;
    cursor: pointer;
    padding-right: 8px;
    padding-left: 8px;
  }
  
  .TodayButton {
    border: unset;
    background-color: #565759;
    height: 20px;
    margin-right: 2px;
    border-radius: 4px;
    color: #e6e6e6;
    outline: unset;
    cursor: pointer;
    padding-right: 8px;
    padding-left: 8px;
    padding-right: 16px;
    padding-left: 16px;
    font-weight: bold;
  }

.rasp-modal-inner{
  min-width: 300px;
}

.itemRasp{
  padding: 2px;
  display: flex;
  justify-content: space-between;
}
.rasp_item_remove,
.rasp_item_addcli{
  width: 12px;
  height: 12px;
  cursor: pointer;
  float: right;
}

.rasp_item_addcli{
  margin-right: 4px;
}

.modal-title-icon>p{
  font-size: 18px;
}
.rasp-modal-inner>div{
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.rasp-modal-inner>div>p{
  font-weight: 550;
  margin-left: 8px;
  max-width: 250px;
}
.calendar-item{
  font-size: 14px;
  max-width: 200px;
}