.monitor div {
  display: flex;
  flex-direction: column;
}
.monitor div p {
  margin-bottom: 8px;
}
.monitor div .type {
  margin-left: 18px;
}

.marg {
  margin-left: 18px;
}
.marg:first-child {
  margin-top: 8px;
}
