.header-ksu th{
    border: 1px solid black;
    padding: 8px 18px;
}
.noup{
    border-collapse: collapse;
}

.filter-month{
    display: flex;
    align-items: flex-end;
}
.filter-month>div{
    padding-right: 28px;
}

.table-real{
    padding-top: 28px;
}
