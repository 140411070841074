.searchSved {
    margin-bottom: 30px;
    display: flex;
    gap: 20px;
}

.inputDateStatic {
    font-size: 20px;
    margin-left: -10px;
}

.sincetoLabel {
    font-weight: bold;
}

.calendarStatic {
    display: flex;
    gap: 15px;
    align-items: center;
}

.buttonsContainer {
    display: flex;
    gap: 20px;
}

.btnDate {
    background: #a5c3f5;
    font-weight: 700;
    font-size: 18px;
    border-radius: 10px;
    border: 1px solid rgb(33 57 95 / 0%);
    box-shadow: 0 1px 1px rgb(50 50 50 / 10%);
    cursor: pointer;
    outline: none;
    color: #21395f;
    padding: 5px 10px;
}