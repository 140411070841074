.monitor div {
 display: flex;
 /* margin-bottom: 18px; */
}
.monitor div p {
 margin-right: 18px;
}
.monitor div a,
.monitor div span {
 font-weight: 600;
 color: blue;
 text-decoration: underline;
 cursor: pointer;
}
