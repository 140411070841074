.modal-red{
    cursor: pointer;
}

.book-items-data>.item-lib{
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.book-items-content>.item-lib{
    width: 100%;
    display: flex;
    margin: 0 10px;
    text-align: center;
    justify-content: space-around;
}

.book-items-header .item-lib{
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.book-items-header .item-lib>div{
    display: flex;
    align-items: center;
}

.book-items-header .del-edit{
    width: 200px;
    display: flex;
    align-items: center;
    margin: 0 10px;
    text-align: center;
}

.book-items-data>.item-lib>div{
    width: calc(100%/6);
}