/* Кнопка добавления изображения */
.btn_plus {
 font-size: 80px;
 width: 80px;
 height: 80px;
 outline: none;
 border: none;
 border-radius: 50%;
 display: flex;
 align-items: center;
 justify-content: center;
 cursor: pointer;
 font-weight: 500;
 color: #5b5b5b;
 background-color: #fefefe;
}

/* Кнопка добавления книги */
.btn_add_book {
 padding: 8px 60px;
 margin: 0 auto;
 outline: none;
 border: none;
 border-radius: 8px;
 background-color: #23a359;
 color: #fff;
 cursor: pointer;
 margin-top: 25px;
}

.btn_disab_book {
 padding: 8px 60px;
 margin: 0 auto;
 outline: none;
 border: none;
 border-radius: 8px;
 background-color: #adadad;
 color: #fff;
 margin-top: 25px;
 cursor: not-allowed;
 pointer-events: none;
}

/* Кнопка добавления жанра */
.btn_add_genre {
 outline: none;
 border: none;
 border-radius: 8px;
 background-color: #23a359;
 color: #fff;
 cursor: pointer;
 padding: 8px 20px;
 margin: 0 auto;
}

/* Кнопка "Выдать" книги в BookItem */
.btn_order_book {
 margin-top: 10px;
 background-color: #2341a3;
 border: none;
 outline: none;
 color: white;
 padding: 10px 20px;
 font-size: 18px;
 border-radius: 10px;
 cursor: pointer;
 transition: 0.3s ease-out;
}

.btn_order_book:hover {
 background-color: #294ec9;
 transition: 0.3s ease-out;
}

/* Кнопка "Вернуть" книги в BookItem */
.btn_return_book {
 margin-top: 10px;
 background-color: #47a323;
 border: none;
 outline: none;
 color: white;
 padding: 10px 20px;
 font-size: 18px;
 border-radius: 10px;
 cursor: pointer;
 transition: 0.3s ease-out;
}

.btn_return_book:hover {
 background-color: #57c92a;
 transition: 0.3s ease-out;
}

/* Кнопка входа */
.btn_enter {
 border: none;
 outline: none;
 color: white;
 cursor: pointer;
 background-color: #063d64;
 border-radius: 8px;
 padding: 13px 0;
 width: 55%;
 margin: 0 auto;
 font-size: 16px;
 transition: 0.3s ease-out;
}

.btn_enter:hover {
 background-color: #09578f;
 transition: 0.3s ease-out;
}

/* Кнопка Cписание книги книги в BookItem*/
.btn_archive_book {
 margin-top: 10px;
 background-color: tomato;
 border: none;
 outline: none;
 color: white;
 padding: 10px 20px;
 font-size: 18px;
 border-radius: 10px;
 cursor: pointer;
 transition: 0.2s ease-out;
 /* margin-right: 20px; */
}

.btn_archive_book:hover {
 background-color: rgb(252, 134, 113);
 transition: 0.2s ease-out;
}

/* Кнопка Добавления инвентарного номера*/
.btn_inv_number_book {
 margin-top: 10px;
 background-color: cadetblue;
 border: none;
 outline: none;
 color: white;
 padding: 10px 20px;
 font-size: 14px;
 border-radius: 10px;
 cursor: pointer;
 transition: 0.2s ease-out;
 /* margin-right: 20px; */
}

.btn_inv_number_book:hover {
 background-color: rgb(61, 101, 102);
 transition: 0.2s ease-out;
}
.btn_library_edit {
 border-radius: 4px;
 font-weight: 700;
 font-size: 18px;
 cursor: pointer;
 font-weight: 700;
 font-size: 14px;
 cursor: pointer;
 position: relative;
 /* width: 200px; */
 margin: 3px auto;
 padding: 4px;
 background: #ff6347bf;
 border-radius: 10px;
 border: 1px solid rgb(33 57 95 / 0%);
 box-shadow: 0 1px 1px rgb(50 50 50 / 10%);
 cursor: pointer;
 outline: none;
 /* font-weight: bold; */
 color: #6e0c0c;
 padding: 5px 15px;
}

.btn_library_edit:hover {
 background: rgb(255, 52, 52);
}

.btn_library_del {
 border-radius: 4px;
 font-weight: 700;
 font-size: 18px;
 cursor: pointer;
 font-weight: 700;
 font-size: 14px;
 cursor: pointer;
 position: relative;

 background: #a5c3f5;
 border-radius: 10px;
 border: 1px solid rgb(33 57 95 / 0%);
 box-shadow: 0 1px 1px rgb(50 50 50 / 10%);
 cursor: pointer;
 outline: none;
 color: #21395f;
 padding: 5px 15px !important;
}

.btn_library_del:hover {
 background: #7eaaf3;
}

.btn_library_create {
 outline: none;
 color: #fff;
 background: rgb(32, 161, 0);
 border: none;
 padding: 3px;
 cursor: pointer;
 border-radius: 4px;
}

.btn_library_create:hover {
 background: rgb(44, 219, 0);
}

.circle_btn_red {
 display: flex;
 align-items: center;
 justify-content: center;
 outline: none;
 color: #fff;
 background: red;
 border: none;
 width: 15px;
 height: 15px;
 padding: 3px;
 cursor: pointer;
 border-radius: 50%;
}

.circle_btn_red:hover {
 background: rgb(255, 52, 52);
}

.wm {
 margin: 0 25px;
}

.mr {
 margin-right: 20px;
}

.type-category-book {
 outline: none;
 border: none;
 padding: 8px 18px;
 cursor: pointer;
 background-color: tomato;
 color: #fff;
 border-radius: 8px;
}

.type-category-book:hover {
 background-color: rgb(255, 132, 110);
}
