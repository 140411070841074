.cellMain,
.cellButton,
.cellHover {
 border-bottom: 1px solid !important;
 padding: 5px !important;
 position: relative;
 background: #a5c3f540 !important;
 border: 1px solid #8caadd !important;
}

.selectOptions {
 border-bottom: 1px solid !important;
 padding: 5px !important;
 position: relative;
 background: #a5c3f540 !important;
 border: 1px solid #8caadd !important;
 width: 450px;
 border-radius: 5px;
 margin: auto;
}

.selectOptionsRadioWrapperSecond {
 margin: auto;
 display: table;
 margin-top: 35px;
}

.selectOptionsRadioWrapper {
 margin: auto;
 display: table;
 margin-top: 5px;
 margin-bottom: 5px;
}

.searchButton {
 margin: auto;
 border-bottom: 1px solid !important;
 padding: 5px !important;
 position: relative;
 background: #a5c3f540 !important;
 border: 1px solid #8caadd !important;
 border-radius: 5px;
 text-align: center;
 cursor: pointer;
}
.numberWrapper {
 margin: auto;
 display: table;
}

.number {
 border-bottom: 1px solid !important;
 padding: 5px !important;
 position: relative;
 background: #a5c3f540 !important;
 border: 1px solid #8caadd !important;
 width: 320px;
 border-radius: 5px;
 margin: auto;
}

.number > input {
 outline: none;
 border-radius: 8px;
 border: 5px solid #c1d4f5;
 padding: 5px;
 width: 308px;
}

.selectOptionsRadio {
 border-bottom: 1px solid !important;
 padding: 5px !important;
 position: relative;
 background: #a5c3f540 !important;
 border: 1px solid #8caadd !important;
 width: 220px;
 border-radius: 5px;
 display: inline;
}

.selectOptionsRadioSecond {
 border-bottom: 1px solid !important;
 padding: 5px !important;
 position: relative;
 background: #a5c3f540 !important;
 border: 1px solid #8caadd !important;
 width: 220px;
 border-radius: 5px;
 margin: auto;
}

.selectOptionsWrapperBack {
 border-bottom: 1px solid !important;
 padding: 5px !important;
 position: relative;
 background: #a5c3f540 !important;
 border: 1px solid #8caadd !important;
 /* width: 220px; */
 border-radius: 5px;
 margin: auto;
}
.searchWrapper {
 display: flex;
}

.leftBlock {
 display: block;
 margin: 10px;
}

.rightBlock {
 display: block;
 margin: 10px;
}
