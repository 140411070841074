.monitor div {
 display: flex;
 flex-direction: column;
}
.monitor div p {
 margin-bottom: 8px;
}
.monitor div .type {
 margin-left: 18px;
}

table {
 /* border: 1px solid rgb(0, 0, 0); */
 border-collapse: collapse;
 /* word-break: break-all; */
}

tbody {
 max-width: 500px;
}

tr,
td,
th {
 /* border: 1px solid rgb(0, 0, 0); */
 padding: 10px 4px;
 text-align: center;
}

.second td {
 width: 30px;
}

.noup td {
 width: 100px;
}

.rotate {
 writing-mode: vertical-lr;
 transform: rotate(180deg);
 height: 150px;
 padding: 4px;
}

.wspace {
 white-space: pre-line;
 width: 150px;
 padding: 4px;
}

.searchSved {
 display: flex;
 gap: 20px;
}

.btn_library_del {
 font-size: 16px;
 padding: 10px 10px;
}

.input-date {
 font-size: 25px;
}

.buttons-container {
 display: flex;
 gap: 20px;
}

.page-out-wrapper {
 max-width: 1080px;
 margin: 0 auto;
}
