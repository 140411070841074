.choice-item-book-add {
 display: flex;
 align-items: center;
 justify-content: space-between;
 border: 1px solid #c7c7c7;
 max-width: 1200px;
 border-radius: 12px;
 padding: 10px 30px;
 margin: 10px 0;
 cursor: pointer;
}

.choice-item-book-add-img {
 width: 14px;
 transform: rotate(180deg);
}

.choice-item-book-add-text {
 font-size: 18px;
}

.choice-item-book-add:hover {
 background-color: #063d64;
 border: 1px solid #063d64;
}

.choice-item-book-add:hover .choice-item-book-add-text {
 color: #fff;
}

.choice-item-book-add:hover .choice-item-book-add-img {
 filter: brightness(15);
}

.search-filter-book__book > p,
.search-filter-book__book > label {
 font-size: 18px;
 font-weight: 600;
}

.search-filter-book__book > label > input {
 margin-left: 20px;
}

.form-add-book,
.form-add-article {
 display: grid;
 grid-template-columns: repeat(3, 1fr);
 grid-column-gap: 0px;
 grid-row-gap: 10px;
}

.st2 {
 display: inline-block;
 align-items: center;
 justify-content: space-between;
 padding: 30px;
 border: 1px solid #8f8f8f;
 border-radius: 15px;
 margin-top: 30px;
 max-width: 1350px;
 justify-content: center;
 vertical-align: middle;
 width: 100%;
}

.st3 {
 position: relative;

 background: #a5c3f5;
 border: 1px solid rgb(17 71 155);
 box-shadow: 0 1px 1px rgb(50 50 50 / 10%);
 cursor: pointer;
 outline: none;
 font-weight: bold;
 color: #21395f;

 padding: 8px 60px;
 margin: 0 auto;

 border-radius: 8px;

 margin-top: 40px;
}
.st3_2 {
 position: relative;

 background: #a5c3f5;
 border: 1px solid rgb(17 71 155);
 box-shadow: 0 1px 1px rgb(50 50 50 / 10%);
 cursor: default;
 outline: none;
 font-weight: bold;
 color: #21395f;

 padding: 8px 60px;
 margin: 0 auto;

 border-radius: 8px;

 margin-top: 40px;
}
.st4 {
 display: block;
}

.st5 {
 margin-top: 25px;
 grid-template-columns: repeat(2, 1fr);
 grid-row-gap: 0;
 align-items: center;
 display: grid;
 grid-template-rows: 1fr;
}

.st8 {
 display: inline;
 float: left;
}

.st9 {
 margin-top: 25px;
 grid-template-columns: repeat(2, 1fr);
 grid-row-gap: 0;
 grid-column-gap: 50px;
 align-items: center;
 display: grid;
 grid-template-rows: 1fr;
 padding: 0 40px 0 40px;
}
.st10 {
 margin: auto;
 margin-top: 15px;
 width: 100%;
}

.st11 > input {
 /* margin-left: 10px; */
 border-radius: 6px;
 width: 100%;
 height: 50px;
 border: 2px solid #4f6ea1;
 background: #dae4f4;
 padding: 20px;
}

.st11 > select {
 border-radius: 6px;
 width: 100%;
 height: 50px;
 border: 2px solid #4f6ea1;
 background: #dae4f4;
 padding-left: 20px;
}
.st12 {
 margin: auto;
 width: 900px;
 height: 600px;
 border: 20px solid #94a7c5;
 border-radius: 5px;
 text-align: center;
 overflow: scroll;
}

.st13 {
 text-align: center;
 margin-top: 15px;
}
.st14 {
 grid-template-columns: repeat(3, 1fr);
 grid-row-gap: 0px;
 grid-column-gap: 5px;
 align-items: center;
 display: grid;
 grid-template-rows: 1fr;
 width: 100%;
 height: 100%;
}

.st14_g {
 grid-template-columns: repeat(2, 1fr);
 grid-row-gap: 0px;
 grid-column-gap: 5px;
 align-items: center;
 display: grid;
 grid-template-rows: 1fr;
 width: 100%;
 height: 100%;
}

.st15 {
 border: 1px solid #6c9eef;
 width: 100%;
 height: 100%;
 border-radius: 10px;
 background: white;
 display: flex;
 justify-content: center;
 align-items: center;
}

.search-filter-book__book,
.search-filter-article {
 flex-basis: 1000px;
 /* max-width: 300px; */
 /* margin-right: 30px; */
}

.search-filter-book__book:not(:first-child),
.search-filter-article:not(:first-child) {
 margin-top: 10px;
}

.search-filter-book__book > input,
.search-filter-article__book > input {
 width: 80%;
 padding: 5px;
 outline: none;
 font-size: 14px;
 border-radius: 8px;
 border: 1px solid #8f8f8f;
 margin-top: 5px;
}

.search-filter-book__book-textarea > textarea {
 width: 100%;
 height: 100px;
 padding: 5px;
 outline: none;
 font-size: 14px;
 border-radius: 8px;
 border: 1px solid #8f8f8f;
 margin-top: 5px;
 resize: none;
}

.rubrika {
 display: flex;
 align-items: center;
 position: relative;
}

.input-rubrika {
 width: 250px;
 padding: 5px;
 outline: none;
 font-size: 14px;
 border-radius: 8px;
 border: 1px solid #8f8f8f;
}

.rubrika > input,
.search-filter-article__book > input {
 width: 250px;
 padding: 5px;
 outline: none;
 font-size: 14px;
 border-radius: 8px;
 border: 1px solid #8f8f8f;
 margin-top: 5px;
}

.rubrika > p {
 margin-bottom: 10px;
}

.search-filter-book-rubrik > select,
.search-filter-article > select {
 padding: 5px;
 outline: none;
 font-size: 14px;
 border-radius: 8px;
 border: 1px solid #8f8f8f;
}

.search-filter-book-rubrik {
 display: flex;
 align-items: center;
}

.search-filter-book__book > div > p {
 font-weight: 700;
 font-size: 28px;
 cursor: pointer;
 margin-left: 10px;
}

.form-add-img {
 width: 230px;
 height: 290px;
 background-color: #d9dbda;
 display: flex;
 align-items: center;
 justify-content: center;
}

.form-add-img-btn {
 display: flex;
 flex-direction: column;
 align-items: center;
}

.unicode {
 width: 300px;
}

/* Модалка жанра */
.modal-content1 {
 width: 300px;
}

.modal-title-icon,
.alert-title-icon {
 display: flex;
 justify-content: space-between;
}

.alert-title-icon > h1 {
 margin-bottom: 20px;
}

.modal-close-icon {
 width: 25px;
 height: 25px;
 cursor: pointer;
}

.modal-content1 > form > input {
 margin: 20px 0;
 width: 250px;
 padding: 5px;
 outline: none;
 font-size: 14px;
 border-radius: 8px;
 border: 1px solid #8f8f8f;
}

/* Жанр */

.searchZhanr {
 display: flex;
 flex-direction: column;
 flex-wrap: wrap;
 position: absolute;
 top: 45px;
 left: 0;
 z-index: 100;
}

.form-add-zhanr-block {
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: space-between;
 font-size: 12px;
 background: aliceblue;
 padding: 2px 10px;
 border-bottom: 1px solid blue;
}

.form-add-zhanr-block > p {
 font-size: 12px;
 width: 335px;
}

.form-add-zhanr-block > div > span {
 margin-left: 15px;
 color: red;
 font-weight: 600;
}

.form-add-zhanr-block:hover {
 background: rgb(175, 218, 255);
 cursor: pointer;
}

.unicode {
 margin-top: 18px;
}

.unicode div {
 margin-top: 8px;
 display: flex;
 align-items: center;
}

.unicode div input {
 margin-right: 8px;
}

.form-add-book__book {
 flex-basis: 750px;
}

.plus {
 margin-left: 20px;
 font-weight: 700;
 font-size: 22px;
 color: blue;
 cursor: pointer;
}

.addItem-form {
 flex-direction: column;
}

.temaWrapper {
 margin-top: 20px;
}

.temaWrapper label {
 font-size: 14px;
 font-weight: 600;
 margin-right: 20px;
}

.temaWrapper label > input {
 margin-left: 8px;
}

.temaTitile {
 font-size: 18px;
 font-weight: 700;
}

.tema > select {
 width: 100%;
}

.select-together {
 display: flex;
 flex-direction: column;
 gap: 15px;
 width: 500px;
 margin-top: 20px;
}

.st1 {
 text-align: center;
}
