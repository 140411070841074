* {
    box-sizing: border-box;
}

.login-wrapper {
    width: 1920px;
    height: 111vh;
    background-color: #f2f5fa;
    font-family: 'Corbel';
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
}


.login-logo {
    width: 170px;
    margin-bottom: 50px;
}

.login-logo>img {
    width: 100%;
}

.log {
    box-shadow: 4px 4px 25px 2px rgba(116, 116, 116, 0.2);
}


.log-wrapper {
    background-color: #fff;
    padding: 30px 66px;
}

.log-wrapper>h1 {
    color: #333333;
    text-align: center;
    font-size: 21px;
}

.log-wrapper>form {
    display: flex;
    flex-direction: column;
}

.login-input>input::placeholder,
.pass-input>input::placeholder {
    color: #b2b4b3;
}

.login-input>input,
.pass-input>input {
    font-size: 18px;
    outline: none;
    border: 1px solid #e5e5e5;
    width: 100%;
    padding: 17px 12px;
}

.login-input>input {
    margin-bottom: 20px;
    margin-top: 50px;
}

.pass-input>input {
    margin-bottom: 45px;
}

.login-input>input::placeholder,
.pass-input>input::placeholder {
    font-size: 14px;
    color: #666666;
}

/* .login-input>input[type='text'],
.pass-input>input[type='text'],
.loginPhone {
    height: 45px;
    width: 360px;
    padding-left: 15px;
    padding-right: 50px;
    border: none;
    outline: none;
}

.log-wrapper>form>select {
    height: 45px;
    outline: none;
    border: none;
    font-size: 18px;
    padding: 0 15px;
    color: #2b2a28;
    margin-top: 30px;
} */