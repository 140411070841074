.monitor div{
    display: flex;
    flex-direction: column;
}
.monitor div p{
    margin-bottom: 8px;
}
.monitor div .type{
    margin-left: 18px;
}
.pop-zhanrs{
    margin-left: 28px;
}
.pop-zhanrs:first-child{
    margin-top: 8px;
}



.formu{
    display: flex;
}
.f1-wrapper{
    margin-right: 50px;
    width: 450px;
    display: flex;
    flex-wrap: wrap;
}
.f1-item{
    width: calc(100%/4);
    height: 55px;
    border: 1px solid black;
}
.f1-item>p{
    padding: 8px;
}
.f2-wrapper{
    border: 1px solid black;
    width: 350px;
    padding: 18px;
}
.f2-header>div{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.f2-line{
    height: 28px;
    border-bottom: 1px solid black;
}