.modal1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
  overflow: auto;
}

.modal-main1 {
  position: fixed;
  background: white;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 520px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: inherit;
  max-height: calc(100vh - 120px);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.modal-main button {
  margin: 0 auto;
  padding: 10px;
  margin-bottom: 20px;
}

.btn-close {
  width: 30%;
  margin: 0 auto;
  outline: none;
  border: none;
  padding: 10px 0;
  border-radius: 8px;
  font-size: 18px;
  background-color: #c0c0c0;
  cursor: pointer;
}

.mt-10{
  margin-top: 10px;
}