.cell {
  font-weight: 900 !important;
  font-size: 13px !important;
  padding: 7px !important;
  width: 33px !important;
  border-bottom: 1px solid rgb(0, 0, 0) !important;
}

.downloadBtn {
  padding: 10px 15px;
  background-color: #f2f5fa;
  cursor: pointer;
  font-weight: 700;
  font-size: 22px;
  background: #a5c3f5;
  border: 1px solid rgb(33 57 95 / 0%);
  outline: none;
  color: #21395f;
}

.downloadBtn:hover {
  transition: 0.3s ease;
  background-color: #c2daf1;
}

.downloadContainer {
  margin-top: 20px;
}

.numCell,
.mainCell,
.rotatedCell {
  border: 1px solid rgb(0, 0, 0) !important;
  color: black !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  padding: 5px 2px !important;
}

.rotatedCell {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
  writing-mode: vertical-rl;
  max-height: 200px;
}

.num {
  border: 1px solid rgb(0, 0, 0) !important;
  padding: 0 5px !important;
}

.numDays {
  font-weight: 600;
  font-size: 18px;
}

.readerRecord {
  font-weight: 800;
  font-size: 23px;
}

.tableContainer {
  overflow-x: initial !important;
  /* height: 1450px; */
  white-space: normal;
}
