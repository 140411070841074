@font-face {
  font-family: "Artefakt";
  src: url(./assets/fonts/Artifakt_Element_Regular.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Corbel";
  src: url(./assets/fonts/corbel.ttf);
  font-weight: 400;
  font-style: normal;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.app {
  display: flex;
  min-height: 100vh;
  font-family: "Artefakt";
}

.app-content {
  margin-left: 300px;
  width: 100%;
}

.page-main {
  max-width: 100%;
  padding: 20px 50px;
  font-family: Artefakt, sans-serif;
}

@media (min-width: 2220px) {
  .app {
    zoom: 150%;
  }
}
