.headerRead {
    background: #a5c3f5;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    color: #21395f;
}

.type {
    background: #f2f5fa;
    padding: 20px;
    border-radius: 20px;
    color: #21395f;
    white-space: nowrap;
    display: flex;
    gap: 8px;
}